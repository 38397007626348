<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>合同管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/ContractTList' }">合同模板列表</el-breadcrumb-item>
            <el-breadcrumb-item>编辑合同模板</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-alert title="编辑合同模板" type="info" center show-icon :closable="true"> </el-alert>
            <el-steps align-center :space="200" :active="ActiveIndex - 0" finish-status="success">
                    <el-step title="基本信息"></el-step>
                    <el-step title="课包信息"></el-step>
                    <el-step title="上课日期"></el-step>
            </el-steps>
            <el-form :model="ModifyContractTemplateForm" :rules="ModifyContractTemplateFormRules"
                ref="ModifyContractTemplateFormRef" label-width="100px" label-position="left">
                <el-tabs v-model="ActiveIndex" :tab-position="'left'" 
                    :before-leave="BeforeTabLeave"
                    @tab-click="TabClick">
                     <el-tab-pane label="基本信息" name="0">
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="编号" prop="Code">
                                    <el-input v-model="ModifyContractTemplateForm.Code"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="名称" prop="Name">
                                    <el-input v-model="ModifyContractTemplateForm.Name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="金额" prop="Money">
                                    <el-input-number style="width :100%" v-model="ModifyContractTemplateForm.Money" 
                                    :precision="2" :step="0.01" :min="0" :max="50000"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="佣金比例" prop="CommissionPercentage">
                                   <el-input-number style="width :100%" v-model="ModifyContractTemplateForm.CommissionPercentage" 
                                   :precision="2" :step="0.01" :min="0" :max="1"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="起始日期：" prop="StartDate">
                                    <el-date-picker
                                        v-model="ModifyContractTemplateForm.StartDate"
                                        @change="DateChange"
                                        type="date"
                                        placeholder="起始日期"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                        style="width :100%">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="结束日期：" prop="EndDate">
                                    <el-date-picker
                                        v-model="ModifyContractTemplateForm.EndDate"
                                        @change="DateChange"
                                        type="date"
                                        placeholder="结束日期"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                        style="width :100%">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                     <!--课包信息-->
                    <el-tab-pane label="课包信息" name="1">
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="课包：" >
                                    <el-input :disabled="true" v-model="CourseClassName"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="基础课时" prop="BasicClassCount">
                                   <el-input-number style="width :100%" v-model="ModifyContractTemplateForm.BasicClassCount" 
                                   :precision="0" :step="1" :min="0" :max="100"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="赠送课时" prop="FreeClassCount">
                                   <el-input-number style="width :100%" v-model="ModifyContractTemplateForm.FreeClassCount" 
                                   :precision="0" :step="1" :min="0" :max="100"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                     <!--上课日期-->
                    <el-tab-pane label="上课日期" name="2">
                        <el-row>
                            <el-col :span=3>
                                <el-checkbox v-model="ModifyContractTemplateForm.Monday" :true-label=1 label="周一"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="ModifyContractTemplateForm.Tuesday" :true-label=1 label="周二"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="ModifyContractTemplateForm.Wednesday" :true-label=1 label="周三"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="ModifyContractTemplateForm.Thursday" :true-label=1 label="周四"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="ModifyContractTemplateForm.Friday" :true-label=1 label="周五"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="ModifyContractTemplateForm.Saturday" :true-label=1 label="周六"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="ModifyContractTemplateForm.Sunday" :true-label=1 label="周天"></el-checkbox>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <el-button type="primary" class="btn_add" @click="SubmitContractTemplate">提交修改</el-button>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import Vue from 'vue';
import {  Alert,Table,TabPane,InputNumber,DatePicker,Upload  } from 'element-ui';
Vue.use(Alert)
Vue.use(Table)
Vue.use(TabPane)
Vue.use(InputNumber)
Vue.use(DatePicker)
Vue.use(Upload)
export default {
    data(){
        return{
            CourseClassName:'',
            ActiveIndex:'0',
            ModifyContractTemplateForm:{
                ID:0,
                Code:'',
                Name:'',
                CourseClassID:'',
                Money:0.00,
                FileUrls:[],
                BasicClassCount:0,
                FreeClassCount:0,
                StartDate:'',
                EndDate:'',
                Monday:0,
                Tuesday:0,
                Wednesday:0,
                Thursday:0,
                Friday:0,
                Saturday:0,
                Sunday:0,
                CommissionPercentage:'',
            },
            ModifyContractTemplateFormRules:{
                Code:[
                    { required: true, message: '请输入合同模板编号', trigger: 'blur' }
                ],
                Name:[
                    { required: true, message: '请输入合同模板名称', trigger: 'blur' }
                ],
                Money:[
                    { required: true, message: '请输入金额', trigger: 'blur' }
                ],
                CommissionPercentage:[
                    { required: true, message: '请输入佣金比例', trigger: 'blur' }
                ],
                StartDate:[
                    { required: true, message: '请选择起始日期', trigger: 'blur' }
                ],
                EndDate:[
                    { required: true, message: '请选择结束日期', trigger: 'blur' }
                ],
                CourseClassID:[
                    { required: true, message: '请选择课包', trigger: 'blur' }
                ]
            }
        }
    },
    created(){
        this.GetParams()
    },
    methods:{
        BeforeTabLeave(activeName,oldActiveName){
            if(oldActiveName === '0' ){
                if(this.ModifyContractTemplateForm.Code.trim().length === 0){
                    this.$message.error('请填写合同编号')
                    return false
                }
                if(this.ModifyContractTemplateForm.Name.trim().length === 0){
                    this.$message.error('请填写合同名称')
                    return false
                }
                if(this.ModifyContractTemplateForm.StartDate.trim().length === 0){
                    this.$message.error('请选择合同起始时间')
                    return false
                }
                if(this.ModifyContractTemplateForm.EndDate.trim().length === 0){
                    this.$message.error('请选择合同结束时间')
                    return false
                }
            }  
            else if(oldActiveName === "1"){
                if(this.ModifyContractTemplateForm.CourseClassID === 0){
                    this.$message.error('请填写合同课包')
                    return false
                }
            } 
        },
        TabClick(){
            if(this.ActiveIndex === '1'){
                this.GetCourseClassName(this.ModifyContractTemplateForm.CourseClassID)
            }
        },
        GetParams(){
            let routerParams = JSON.parse(sessionStorage.getItem('modifycontracttemplate'))
            this.ModifyContractTemplateForm.ID = routerParams.id
            this.ModifyContractTemplateForm.Code = routerParams.code
            this.ModifyContractTemplateForm.Name = routerParams.name
            this.ModifyContractTemplateForm.CourseClassID = routerParams.courseClassID
            this.ModifyContractTemplateForm.Money = routerParams.money
            this.ModifyContractTemplateForm.FileUrls = null
            this.ModifyContractTemplateForm.BasicClassCount = routerParams.basicClassCount
            this.ModifyContractTemplateForm.FreeClassCount = routerParams.freeClassCount
            this.ModifyContractTemplateForm.StartDate = routerParams.startDate
            this.ModifyContractTemplateForm.EndDate = routerParams.endDate
            this.ModifyContractTemplateForm.Monday = routerParams.monday
            this.ModifyContractTemplateForm.Tuesday = routerParams.tuesday
            this.ModifyContractTemplateForm.Wednesday = routerParams.wednesday
            this.ModifyContractTemplateForm.Thursday = routerParams.thursday
            this.ModifyContractTemplateForm.Friday = routerParams.friday
            this.ModifyContractTemplateForm.Saturday = routerParams.saturday
            this.ModifyContractTemplateForm.Sunday = routerParams.sunday
            this.ModifyContractTemplateForm.CommissionPercentage = routerParams.commissionPercentage
        },
        DateChange(){
            var starttime = new Date(this.ModifyContractTemplateForm.StartDate);
            var endtime = new Date(this.ModifyContractTemplateForm.EndDate);
            if(starttime >=endtime) 
            { 
                this.$alert('开始时间不能大于结束时间！', '提示', {
                    confirmButtonText: '确定'
                });
                return false; 
            }
        },
        async GetCourseClassName(id){
            const {data:res} = await this.$http.get('CourseClassFication/v1/CourseClassFicationsByID',{params: {ID : id}})
            if(res.code !== 1){
                return this.$message.error('获取课包名称失败');
            }
            this.CourseClassName = res.data
        },
        async SubmitContractTemplate(){
            this.$refs.ModifyContractTemplateFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            await this.$http.put('ContractTemplate/v1/ContractTemplateInfo',this.ModifyContractTemplateForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    return this.$message.error('修改合同模板失败')
                }
            }).catch(function (error) {
                return this.$message.error(error)
            });
            this.validret = false
            this.$message.success('修改合同模板成功')
            this.$router.push('/ContractTList')
        }
    }
}
</script>